import React from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Lottie from "react-lottie";
import animationDataMobile from "../Lottie/IntroducingTurboMobile.json";
import animationDataDesktop from "../Lottie/IntroducingTurboDesktop.json";

const HeroSection = () => {
  //dimensions height width
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }
    window.addEventListener("resize", handleResize);

    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  });

  //lottie options
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData:
      dimensions.width > 600 ? animationDataDesktop : animationDataMobile,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <section className="section-hero">
      <div>
        <div className="hiro-rightMobile d-flex align-items-center w-100 justify-content-end">
          <Lottie isClickToPauseDisabled={true} options={defaultOptions} />
        </div>
        <div className="mainTop-content">
          <Container>
            <div className="row-hero-section align-items-center">
              <div className="textBlok-left">
                <h4 className="sub-heading">INTRODUCING</h4>
                <div className="turboHiro">
                  <img
                    src="./Assets/turbo-arrow-img.png"
                    className="lg-blockLogo"
                    alt=""
                  />
                  <img
                    src="./Assets/mobile-turbo-arrow-img.png"
                    className="sm-blockLogo"
                    alt=""
                  />
                </div>
                <p>
                  Turbo is a Web3 navigation app that gamifies the driving
                  experience, bringing fun and earning to navigation users
                  around the world.
                </p>
                <div className="d-flex align-items-center flw-Col">
                  <Button className="connectWallet d-flex align-items-start justify-content-center">
                    <img src="./Assets/apple_logo.png" alt="" />
                    <span>Download for iOS</span>
                  </Button>
                  <Button className="connectWallet d-flex align-items-start justify-content-center">
                    <div className="hiro-leftMobile">
                      <img src="./Assets/file.png" alt="" />
                    </div>
                    <span>Lite Paper</span>
                  </Button>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
