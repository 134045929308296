import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Lottie from "react-lottie";
import animationData from "../Lottie/RoadReport.json";

export const RoadReport = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <section className="section-road-report">
      <div>
        <Container>
          <Row className="row-road-report align-items-center">
            <Col md={6}>
              <div className="decentralized-block">
                <span className="decentralized">Decentralized</span>
                <h1 className="heading">Road Reports</h1>
                <img src="./Assets/Arrow-light.png" />
                <ul>
                  <li>
                    <h4 className="sub-heading">Drive Better Together</h4>
                    <span>
                      Let's be honest, traffic sucks. Turbo allows drivers to
                      report live traffic data & other road reports that help
                      create quicker routes for other drivers in the Turbo
                      ecosystem.
                    </span>
                  </li>
                  <li>
                    <h4 className="sub-heading">Live Road Reports</h4>
                    <span>
                      Road reports are created in real time & increase the
                      datapoints used in the Turbo routing algorithm.
                    </span>
                  </li>
                  <li>
                    <h4 className="sub-heading">Confirm or Deny Reports</h4>
                    <span>
                      When drivers approach a road report, they can either
                      confirm or deny whether the road report is still valid.
                    </span>
                  </li>
                </ul>
              </div>
            </Col>
            <Col md={6}>
              <div>
                {/* <img src="./Assets/Round.png" /> */}
                <Lottie isClickToPauseDisabled={true} options={defaultOptions} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default RoadReport;
