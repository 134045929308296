import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import CarGif from "../GIF/Car-IN.gif";
import CarGifLoop from "../GIF/Car-LOOP.gif";

const TurboVehicle = () => {
  const [isAnimOver, setIsAnimOver] = useState(false);
  const carEnterHandler = () => {
    setTimeout(() => {
      // write your functions
      setIsAnimOver(true);
    }, 2800);
  };

  const carExitHandler = () => {
    clearInterval();
    setIsAnimOver(false);
  };

  let carInClass, carLoopClass;
  if (isAnimOver) {
    carLoopClass = "d-none";
    carInClass = "";
  } else {
    carLoopClass = "";
    carInClass = "d-none";
  }
  return (
    <section className="main-section-turboVehicles">
      <Container>
        <Row className="align-items-center">
          <Col md={6} className="turboVehical-left">
            <div className="leftImg">
              <h1 className="heading">Turbo Vehicles</h1>
              <img src="./Assets/Arrow-light.png" />
              <span>
                Different vehicle types has advantages over others, with some
                vehicles better equipped to collect more unique resources, while
                other vehicles can last a longer duration without needing
                repairs. The different purposes of vehicles allows drivers to
                strategically plan their roadtrips and the vehicle they should
                equip for their drive.
              </span>
            </div>
            <div className="main-innervehiclesText d-flex align-items-start flex-wrap">
              <div className="subText w-50">
                <h2>Luck</h2>
                <span>
                  Luck determines the 'type' of resources which will likely be
                  collected during a road trip.
                </span>
              </div>
              <div className="subText w-50">
                <h2>Power</h2>
                <span>
                  This determines the ability of the car to earn additional SDC
                  coins from driving it.
                </span>
              </div>
              <div className="subText w-50">
                <h2>Durability</h2>
                <span>
                  This determines the rate at which the vehicle will
                  deteriorate, as well as the cost to repair the vehicle.
                </span>
              </div>
              <div className="subText w-50">
                <h2>Storage</h2>
                <span>
                  This determines the amount of resources which can be collected
                  in a 24-hour period by the vehicle during driving.
                </span>
              </div>
            </div>
          </Col>
          <Col
            md={6}
            className="d-flex align-items-center justify-content-center beforeImg"
          >
            <div className="rightImg d-flex align-items-center justify-content-center">
              {/* <img src="./Assets/right-vehicles.png" /> */}
              <ParallaxProvider>
                <Parallax onEnter={carEnterHandler} onExit={carExitHandler}>
                  {/* first */}
                  <img className={carLoopClass} src={CarGif} />
                  {/* last */}
                  <img className={carInClass} src={CarGifLoop} />
                </Parallax>
              </ParallaxProvider>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default TurboVehicle;
