import Header from "./components/Header";
import "bootstrap/dist/css/bootstrap.min.css";
import Body from "./components/Body";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Whitelist from "./Pages/Whitelist";
import Staking from "./Pages/Staking";
import Adverties from "./Pages/Adverties";
import PageNotFound from "./Pages/PageNotFound";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Header />}>
          <Route index element={<Body />} />
          <Route path="whitelist" element={<Whitelist />} />
          <Route path="staking" element={<Staking />} />
          <Route path="adverties" element={<Adverties />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
