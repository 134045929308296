import React from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import "../Style/HeaderFooter.scss";
import Button from "react-bootstrap/Button";
import { Link, Outlet } from "react-router-dom";

const Header = () => {
  // const stickyHeader = useRef();
  // useLayoutEffect(() => {
  //   const mainHeader = document.querySelector(".mainHeader");
  //   let fixedTop = stickyHeader.current.offsetTop;
  //   const fixedHeader = () => {
  //     if (window.pageYOffset > fixedTop) {
  //       mainHeader.classList.add("header-active");
  //     } else {
  //       mainHeader.classList.remove("header-active");
  //     }
  //   };
  //   window.addEventListener("scroll", fixedHeader);
  // }, []);
  return (
    <>
      <header className="mainHeader ">
        <div>
          <Navbar expand="lg">
            <Container>
              <Navbar.Brand href="#home">
                <img src="./Assets/logo.png" />
              </Navbar.Brand>
              <Navbar.Toggle
                aria-controls="basic-navbar-nav"
                className="mobileMenu"
              >
                <span></span>
                <span></span>
                <span></span>
              </Navbar.Toggle>
              <Navbar.Collapse id="basic-navbar-nav">
                <ul className="m-auto">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/whitelist">Features</Link>
                  </li>
                  <li>
                    <Link to="/staking">Vehicles</Link>
                  </li>
                  <li>
                    <Link to="/adverties">Advertise</Link>
                  </li>
                </ul>
                <div>
                  <Button className="connectWallet d-flex align-items-center">
                    <img src="./Assets/twitter.png" />
                    <span>Twitter</span>
                  </Button>
                </div>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      </header>
      <Outlet />
    </>
  );
};
export default Header;
