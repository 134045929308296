import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Lottie from "react-lottie";
import animationData from "../Lottie/CarPlay.json";

const CarPlay = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <section className="section-carplay">
      <div>
        <Container>
          <Row>
            <Col lg={12}>
              <h1 className="heading">
                Apple CarPlay & Android Auto in development.
              </h1>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="main-sectionmobileCarplay">
        <Lottie isClickToPauseDisabled={true} options={defaultOptions} />
      </div>
    </section>
  );
};

export default CarPlay;
