import React, { useState, useEffect } from "react";
import animationDataMobile from "../Lottie/LoadingMobile.json";
import animationDataDesktop from "../Lottie/LoadingDesktop.json";
import Lottie from "react-lottie";

const Loading = () => {
  //dimensions height width
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }
    window.addEventListener("resize", handleResize);

    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  });

  //lottie options
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData:
      dimensions.width > 600 ? animationDataDesktop : animationDataMobile,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  //const [isLoading, setLoading] = useState(true);
  function someRequest() {
    //Simulates a request; makes a "promise" that'll run for 2.5 seconds
    return new Promise((resolve) => setTimeout(() => resolve(), 2500));
  }

  useEffect(() => {
    someRequest().then(() => {
      const loaderElement = document.querySelector(".loader-container");
      loaderElement.classList.add("lodingRemove");
      document.querySelector("body").classList.add("overflow");
      //setLoading(false);
    });
  }, []);

  // if (isLoading) {
  //   return null;
  // }

  return (
    <div className="mainLoder-section">
      <div className="inner-box">
        <div>
          <img src="./Assets/logo.png" alt="logo" className="loder-logo" />
          <h2>WEB3 NAVIGATION </h2>
        </div>
        <div className="d-flex align-items-end">
          <h2 className="d-flex">Loading</h2>
          <h2 className="mb-0 d-flex">
            <span className="dots ms-1">.</span>
            <span className="dots ms-1">.</span>
            <span className="dots ms-1">.</span>
          </h2>
        </div>
      </div>
      <div className="loderImg">
        <Lottie isClickToPauseDisabled={true} options={defaultOptions} />
      </div>
    </div>
  );
};
export default Loading;
