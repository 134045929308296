import React, { Fragment } from "react";
import "../Style/Body.scss";
import CarPlay from "./CarPlay";
import HeroSection from "./HeroSection";
import PrivateSwapEvent from "./PrivateSwapEvent";
import RoadReport from "./RoadReport";
import SkinSelect from "./SkinSelect";
import TurboVehicle from './TurboVehicle';

const Body = () => {
  return (
    <Fragment>
      <HeroSection />
      {/* <PrivateSwapEvent /> */}
      <SkinSelect/>
      <RoadReport/>
      <CarPlay/>
      <TurboVehicle/>
    </Fragment>
  );
};

export default Body;
