import React, { useEffect } from "react";
import { useScrollPercentage } from "react-scroll-percentage";
import { Col, Container, Row } from "react-bootstrap";
import Image from "react-bootstrap/Image";
const SkinSelect = () => {
  //Scroll percentage
  const [ref, percentage] = useScrollPercentage({
    /* Optional options */
    threshold: 0,
  });

  //dimensions height width
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }
    window.addEventListener("resize", handleResize);

    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let active1, active2, active3, active4, display;
  let per = percentage.toPrecision(2);
  let img = [];
  img[0] = "./Assets/turbo-app-1.png";
  img[1] = "./Assets/turbo-app-2.png";
  img[2] = "./Assets/turbo-app-3.png";
  img[3] = "./Assets/turbo-app-4.png";

  if (per < 0.35) {
    // img = "./Assets/turbo-app-1.png";
    active1 = "active";
    display = 1;
  } else if (per < 0.5 && per > 0.34) {
    // img = "./Assets/turbo-app-2.png";
    active2 = "active";
    display = 2;
  } else if (per < 0.65 && per > 0.49) {
    // img = "./Assets/turbo-app-3.png";
    active3 = "active";
    display = 3;
  } else if (per > 0.64) {
    // img = "./Assets/turbo-app-4.png";
    active4 = "active";
    display = 4;
  } else {
    // img = "./Assets/turbo-app-1.png";
    active1 = "active";
    display = 1;
  }

  return (
    <>
      {dimensions.width > 767 ? (
        <div ref={ref} className="mainSticky-section">
          <section className="section-skin-select">
            <div>
              <Container>
                <Row className="row-skin-select align-items-center">
                  <Col md={6}>
                    <div className="leftPhone-screen">
                      <Image src={img[0]} className={display === 1 ? `show chnageImg-mobile` : `hide chnageImg-mobile`} />
                      <Image src={img[1]} className={display === 2 ? `show chnageImg-mobile` : `hide chnageImg-mobile`} />
                      <Image src={img[2]} className={display === 3 ? `show chnageImg-mobile` : `hide chnageImg-mobile`} />
                      <Image src={img[3]} className={display === 4 ? `show chnageImg-mobile` : `hide chnageImg-mobile`} />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div>
                      <ul className="main-textBox">
                        <li>
                          <h1 className="heading">Turbo App Features</h1>
                        </li>
                        <li className={active1}>
                          <h4 className="sub-heading">Mine SDC</h4>
                          <span>
                            Turbo drivers earn SDC for each safe mile they drive
                            on their routes.
                          </span>
                        </li>
                        <li className={active2}>
                          <h4 className="sub-heading">Collect Resources</h4>
                          <span>
                            Drivers can collect valuable in-game resources which
                            can be used to build vehicle NFTs.
                          </span>
                        </li>
                        <li className={active3}>
                          <h4 className="sub-heading">Build Vehicles</h4>
                          <span>
                            Vehicle NFTs are assembled using SDC & resources
                            collected in the Turbo metaverse. Vehicles can be
                            traded or rented between drivers.
                          </span>
                        </li>
                        <li className={active4}>
                          <h4 className="sub-heading">Upgrade Vehicles</h4>
                          <span>
                            Upgrade vehicles to maximize earning potential. Keep
                            your vehicles maintained to increase efficiency and
                            decrease damage to your vehicles.
                          </span>
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </section>
        </div>
      ) : (
        <section className="section-skin-select">
          <div>
            <Container>
              <Row className="row-skin-select align-items-center">
                <Col md={12}>
                  <div>
                    <ul className="main-textBox">
                      <li>
                        <h1 className="heading">Turbo App Features</h1>
                      </li>
                      <li className="active">
                        <img
                          src="./Assets/turbo-app-1.png"
                          className="chnageImg-mobile"
                        />
                        <div className="mobile-textData">
                          <h4 className="sub-heading">Mine SDC</h4>
                          <span>
                            Turbo drivers earn SDC for each safe mile they drive
                            on their routes.
                          </span>
                        </div>
                      </li>
                      <li className="active">
                        <img
                          src="./Assets/turbo-app-2.png"
                          className="chnageImg-mobile"
                        />
                        <div className="mobile-textData">
                          <h4 className="sub-heading">Collect Resources</h4>
                          <span>
                            Drivers can collect valuable in-game resources which
                            can be used to build vehicle NFTs.
                          </span>
                        </div>
                      </li>
                      <li className="active">
                        <img
                          src="./Assets/turbo-app-3.png"
                          className="chnageImg-mobile"
                        />
                        <div className="mobile-textData">
                          <h4 className="sub-heading">Build Vehicles</h4>
                          <span>
                            Vehicle NFTs are assembled using SDC & resources
                            collected in the Turbo metaverse. Vehicles can be
                            traded or rented between drivers.
                          </span>
                        </div>
                      </li>
                      <li className="active">
                        <img
                          src="./Assets/turbo-app-4.png"
                          className="chnageImg-mobile"
                        />
                        <div className="mobile-textData">
                          <h4 className="sub-heading">Upgrade Vehicles</h4>
                          <span>
                            Upgrade vehicles to maximize earning potential. Keep
                            your vehicles maintained to increase efficiency and
                            decrease damage to your vehicles.
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </section>
      )}
    </>
  );
};

export default SkinSelect;
